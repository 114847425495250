import React from 'react';

const SlideWrapper = ({active, backgroundColor, backgroundImage, children, overTop, hasSlides}) => (
	<div
		className={"homepage__slide" + (overTop ? ' homepage__slide--over-top' : '') + (active ? ' homepage__slide--active' : '') + (hasSlides ? ' homepage__slide--has-slides' : '')}
		style={{backgroundColor, backgroundImage}}>
		{children}
	</div>
);

export default SlideWrapper;