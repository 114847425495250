import React, {Component} from "react";
import BardziejButton from "../BardziejButton";
import {FormattedMessage} from "react-intl";
import Heading from '../Typography/Heading';
import ArrowUp from '../../shared/icons/arrow-top.svg'
import ArrowDown from '../../shared/icons/arrow-bottom.svg'
import {withPrefix} from "gatsby";

class BardziejSlide extends Component {

	constructor(props) {
		super(props);

		this.state = {
			videoSize: 'fhd',
			mobileVersion: false,
			currentSlide: 0,
			slideDebounce: false,
		};

		this.realizationsCatch = false;
		this.debounceTimeout = null;
		this.videoRefs = {};

		this.slidesConfig = [
			{
				title: 'home.realizations.lemon.heading',
				heading: 'home.realizations.lemon.textHeading',
				description: 'home.realizations.lemon.text',
				buttonText: 'home.realizations.see',
				videoFile: 'lemon',
				href: 'projects/lemon'
			},
			{
				title: 'home.realizations.black.heading',
				heading: 'home.realizations.black.textHeading',
				description: 'home.realizations.black.text',
				buttonText: 'home.realizations.see',
				videoFile: 'black',
				href: 'projects/black'
			},
			{
				title: 'home.realizations.balcerzak.heading',
				heading: 'home.realizations.balcerzak.textHeading',
				description: 'home.realizations.balcerzak.text',
				buttonText: 'home.realizations.see',
				videoFile: 'balcerzak',
				href: 'projects/balcerzak'
			},
			{
				title: 'home.realizations.carSharing.heading',
				heading: 'home.realizations.carSharing.textHeading',
				description: 'home.realizations.carSharing.text',
				buttonText: 'home.realizations.see',
				videoFile: 'carsharing',
				href: 'https://www.intellect.pl/platforma-carsharing/'
			},
			{
				title: 'home.realizations.digitalSignage.heading',
				heading: 'home.realizations.digitalSignage.textHeading',
				description: 'home.realizations.digitalSignage.text',
				buttonText: 'home.realizations.see',
				videoFile: 'digital_signage',
				href: 'https://www.intellect.pl/digital-signage/'
			},
			{
				title: 'home.realizations.eLearning.heading',
				heading: 'home.realizations.eLearning.textHeading',
				description: 'home.realizations.eLearning.text',
				buttonText: 'home.realizations.see',
				videoFile: 'e_learning',
				href: 'https://www.intellect.pl/video-e-learning/'
			},
		];
	}

	componentDidMount() {
		window.addEventListener('resize', this.checkForMobileVersion);
		document.addEventListener('scroll', this.handleScroll);
		document.addEventListener('keydown', this.handleKeyDown);

		this.checkForMobileVersion();

		if (this.props.active === true) {
			this.setupSlide(0, null);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkForMobileVersion);
		document.removeEventListener('scroll', this.handleScroll);
		document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.active === false && nextProps.active === true) {
			this.setupSlide(this.state.currentSlide, null);
		}
	}

	handleKeyDown = (event) => {
		if (this.props.active === false) {
			return false;
		}

		if (event.keyCode === 38) {
			event.preventDefault();
			this.getPrevSlide();
		}

		if (event.keyCode === 40) {
			event.preventDefault();
			this.getNextSlide();
		}
	};

	handleScroll = () => {
		clearTimeout(this.debounceTimeout);
		this.debounceTimeout = setTimeout(this.slideTightening, 100);
	};

	slideTightening = () => {
		if (this.state.mobileVersion) {
			const realizationSection = document.getElementsByClassName('realizations-slide')[0];
			const bounding = realizationSection.getBoundingClientRect();

			if (this.realizationsCatch === false) {
				// catch him!
				if (Math.abs(bounding.y) < bounding.height / 2) {
					this.scrollWindowTo(Math.round(window.scrollY + bounding.y), 4000);

					this.realizationsCatch = true;

					// return false;
				}
			} else {
				// reset after out of screen
				if ((bounding.y - bounding.height) > 50 || (bounding.y + bounding.height) < -50) {
					this.realizationsCatch = false;
				}
			}

			const header = document.getElementsByClassName('header');
			if (bounding.y < 2 && bounding.y + bounding.height > 100) {
				for (let i = 0; i < header.length; i++) {
					header[i].classList.add('header--unseen');
				}
			} else {
				for (let i = 0; i < header.length; i++) {
					header[i].classList.remove('header--unseen');
				}
			}

			return false;
		}
	};

	scrollWindowTo(to) {
		const start = window.scrollY;
		const change = to - start;

		const duration = 400;

		const increment = 20;
		let currentTime = 0;

		Math.easeInOutQuad = function (t, b, c, d) {
			t /= d / 2;
			if (t < 1) return c / 2 * t * t + b;
			t--;
			return -c / 2 * (t * (t - 2) - 1) + b;
		};

		const animateScroll = (() => {
			currentTime += increment;
			window.scrollTo(0, Math.easeInOutQuad(currentTime, start, change, duration));
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		});

		animateScroll();
	}

	checkForMobileVersion = () => {
		this.setState({
			mobileVersion: window.matchMedia("(max-width: 1024px)").matches,
			videoSize: window.matchMedia("(max-width: 768px)").matches ? 'mobile' : 'fhd'
		});
	};

	getNextSlideNumber(currentSlide) {
		currentSlide += 1;

		if (currentSlide === this.slidesConfig.length) {
			currentSlide = 0;
		}

		return currentSlide;
	}

	getNextSlide() {
		this.setupSlide(this.getNextSlideNumber(this.state.currentSlide), this.state.currentSlide);
	}

	getPrevSlideNumber(currentSlide) {
		if (currentSlide === 0) {
			return this.slidesConfig.length - 1;
		}

		return currentSlide - 1;
	}

	getPrevSlide() {
		this.setupSlide(this.getPrevSlideNumber(this.state.currentSlide), this.state.currentSlide);
	}

	getNextSlideTitle(currentSlide) {
		return this.slidesConfig[this.getNextSlideNumber(currentSlide)].title;
	}

	getPrevSlideTitle(currentSlide) {
		return this.slidesConfig[this.getPrevSlideNumber(currentSlide)].title;
	}

	setupSlide(currentSlide, prevSlide) {
		if (this.state.slideDebounce === true) {
			return false;
		}

		if (this.videoRefs[currentSlide]) {
			this.videoRefs[currentSlide].currentTime = 0;
			this.videoRefs[currentSlide].play();
		}

		if (prevSlide && this.videoRefs[prevSlide]) {
			this.videoRefs[prevSlide].pause();
		}

		this.setState({
			currentSlide,
			slideDebounce: true,
		});

		setTimeout(() => {
			this.setState({
				slideDebounce: false,
			});
		}, 1000);
	}

	setVideoRef(index, ref) {
		this.videoRefs[index] = ref;
	}

	render() {
		return (
			<div id="projekty">
				{this.slidesConfig.map((slide, index) => (
					<section key={index}
					         className={"realizations-slide" + (this.state.currentSlide === index ? ' realizations-slide--active' : '')}>
						<video ref={(ref) => this.setVideoRef(index, ref)} className="video-full" muted playsInline
						       loop preload="none">
							<source src={withPrefix(`/video/project/${slide.videoFile}_${this.state.videoSize}.mp4`)}
							        type="video/mp4"/>
						</video>
						<div className="slide__column slide__column--head">
							<div data-realization-navigate="prev" onClick={this.getPrevSlide.bind(this)}>
								<div className="navigate-text">
									<FormattedMessage id={this.getPrevSlideTitle(index)}/>
								</div>
								<ArrowUp/>
							</div>
							<div className="slide__column-headings">
								<Heading title="home.realizations.claim" size="medium"/>
								{!this.state.mobileVersion &&
								<h1 className="heading heading--large"><FormattedMessage id={slide.title}/></h1>}
							</div>
							<div data-realization-navigate="next" onClick={this.getNextSlide.bind(this)}>
								<div className="navigate-text">
									<FormattedMessage id={this.getNextSlideTitle(index)}/>
								</div>
								<ArrowDown/>
							</div>
						</div>
						<div className="slide__column slide__column--content">
							<div className="slide__text">
								{this.state.mobileVersion &&
								<h1 className="heading heading--large"><FormattedMessage id={slide.title}/></h1>}
								{slide.heading && <h2 className="heading heading--small heading--white heading--light">
									<FormattedMessage id={slide.heading}/>
								</h2>}
								<div className="additional-text">
									<FormattedMessage id={slide.description}/>
								</div>
								<BardziejButton
									href={slide.href.indexOf('http') === -1 ? slide.href : null}
									hrefext={slide.href.indexOf('http') === 0 ? slide.href : null}
									title={slide.buttonText} modifier="bardziej-button--white"/>
							</div>
						</div>
					</section>
				))}
			</div>
		);
	}
}

export default BardziejSlide;
