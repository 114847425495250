import React from "react";
import {FormattedMessage} from "react-intl";
import Link from "../Link";

const CompetenceItem = ({title, desc, url}) => (
	<div className="competence__item">
		{url ? (
			<Link to={url} className="competence__link">
				<h3 className="competence__title">
					<FormattedMessage id={title} />
				</h3>
			</Link>
		) : (
			<h3 className="competence__title">
				<FormattedMessage id={title} />
			</h3>
		)}
		<div className="competence__desc">
			<FormattedMessage id={desc} />
		</div>
	</div>
);

export default CompetenceItem;
