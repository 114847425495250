import {Component} from 'react';

class HomepageSlide extends Component {

	constructor(props) {
		super(props);

		this.state = {
			videoSize: 'fhd',
		};

		this.videoPlayer = null;

		this.setVideoPlayerRef = element => {
			this.videoPlayer = element;
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.checkForVideoSize);

		this.checkForVideoSize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkForVideoSize);
	}

	checkForVideoSize = () => {
		this.setState({
			videoSize: window.matchMedia("(max-width: 768px)").matches ? 'mobile' : 'fhd'
		});
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps.active === false) {
			this.videoPlayer.pause();
			this.videoPlayer.currentTime = 0;
		} else {
			this.videoPlayer.play();
		}
	}
}

export default HomepageSlide;
