import React, {Component} from 'react';

import withLayout from '../layout';
import './index.scss';
import BardziejSlide from "../components/Homepage/BardziejSlide";
import WeSlide from "../components/Homepage/WeSlide";
import SlideWrapper from "./../components/Homepage/SlideWrapper";
import CompetencesSlide from "./../components/Homepage/CompetencesSlide";
import RealizationsSlide from "./../components/Homepage/RealizationsSlide";
import ClientsSlide from "./../components/Homepage/ClientsSlide";
import ContactSlide from "../components/Contact/ContactSlide";
import SlidePagination from "./../components/Homepage/SlidePagination";

class Index extends Component {

	constructor(props) {
		super(props);

		this.slidesCount = 6;
		this.workingWidth = 1024;
		this.scrollTimeout = null;

		this.state = {
			slide: 0,
			animation: false,
			reverseAnimation: false,
			touchStartPoint: null,
			realizationsCatch: false,
			currentSlides: [],
		};

		this.slidesHash = {
			'#bardziej': 0,
			'#my': 1,
			'#kompetencje': 2,
			'#projekty': 3,
			'#klienci': 4,
			'#kontakt': 5,
		};
	}

	componentDidMount() {
		document.body.addEventListener('wheel', this.handleMouseWheel);
		document.body.addEventListener('touchstart', this.handleTouchStart);
		document.body.addEventListener('touchend', this.handleTouchEnd);
		document.body.addEventListener('touchmove', this.handleTouchMove, {passive: false});

		document.addEventListener('keydown', this.handleKeyDown);
		window.addEventListener('scroll', this.watchForCurrentMobileSlide);

		document.body.classList.add('disable-overflow');

		const slideNumber = this.getSlideNumberByHash(window.location.hash);
		this.setupSlide(slideNumber !== null ? slideNumber : 0);
	}

	componentWillUnmount() {
		document.body.removeEventListener('wheel', this.handleMouseWheel);
		document.body.removeEventListener('touchstart', this.handleTouchStart);
		document.body.removeEventListener('touchend', this.handleTouchEnd);
		document.body.removeEventListener('touchmove', this.handleTouchMove);

		document.removeEventListener('keydown', this.handleKeyDown);
		window.removeEventListener('scroll', this.watchForCurrentMobileSlide);

		document.body.classList.remove('disable-overflow');
	}

	componentWillReceiveProps(nextProps, nextContext) {
		const slideNumber = this.getSlideNumberByHash(window.location.hash);

		if (slideNumber === this.state.slide) {
			return false;
		}

		if (slideNumber !== null) {
			this.animateMoveSlides(slideNumber, slideNumber < this.state.slide);
		}
	}

	getSlideNumberByHash = (hash) => {
		if (this.slidesHash[hash] !== undefined) {
			return this.slidesHash[hash];
		}

		return null;
	};

	handleMouseWheel = (event) => {
		if (window.innerWidth <= this.workingWidth) {
			return false;
		}

		if (Math.abs(event.deltaY) > 30) {
			this.getNextSlide(event.deltaY < 0);
		}
	};

	handleTouchStart = (event) => {
		if (window.innerWidth <= this.workingWidth) {
			return false;
		}

		// event.preventDefault();

		this.setState({
			touchStartPoint: {
				x: event.changedTouches[0].pageX,
				y: event.changedTouches[0].pageY
			}
		});

		return false;
	};

	handleTouchEnd = (event) => {
		if (window.innerWidth <= this.workingWidth) {
			return false;
		}

		event.stopPropagation();

		const startPoint = this.state.touchStartPoint;
		const endPoint = {
			x: event.changedTouches[0].pageX,
			y: event.changedTouches[0].pageY
		};

		if (this.calculatePointsDistance(startPoint, endPoint) > 100) {
			this.getNextSlide(endPoint.x > startPoint.x || endPoint.y > startPoint.y);
		}
	};

	handleScroll(event) {
		if (window.innerWidth <= this.workingWidth) {
			const realizationSection = document.querySelector('.realizations-slide');
			const bounding = realizationSection.getBoundingClientRect();

			if (this.state.realizationsCatch === false) {
				// catch him!
				if (Math.abs(bounding.y) < bounding.height / 4) {
					this.scrollWindowTo(window.scrollY + bounding.y, 4000);

					this.setState({
						realizationsCatch: true
					});

					event.preventDefault();

					return false;
				}
			} else {
				// reset after out of screen
				if ((bounding.y - bounding.height) > 50 || (bounding.y + bounding.height) < -50) {
					this.setState({
						realizationsCatch: false
					});
				}
			}

			return false;
		}
	}

	scrollWindowTo(to) {
		const start = window.scrollY;
		const change = to - start;

		const duration = 600;

		const increment = 20;
		let currentTime = 0;

		Math.easeInOutQuad = function (t, b, c, d) {
			t /= d / 2;
			if (t < 1) return c / 2 * t * t + b;
			t--;
			return -c / 2 * (t * (t - 2) - 1) + b;
		};

		const animateScroll = (() => {
			currentTime += increment;
			window.scrollTo(0, Math.easeInOutQuad(currentTime, start, change, duration));
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		});

		animateScroll();
	};

	calculatePointsDistance(startPoint, endPoint) {
		return Math.sqrt(Math.pow(startPoint.x - endPoint.x, 2) + Math.pow(startPoint.y - endPoint.y, 2));
	}

	handleTouchMove = (event) => {
		if (window.innerWidth <= this.workingWidth) {
			return false;
		}

		if (this.calculatePointsDistance(this.state.touchStartPoint, {
			x: event.changedTouches[0].pageX,
			y: event.changedTouches[0].pageY
		}) >= 50) {
			event.preventDefault();
		}
	};

	handleKeyDown = (event) => {
		if (event.target.tagName.toLowerCase() === 'input' || event.target.tagName.toLowerCase() === 'textarea') {
			return null;
		}

		if (event.keyCode === 37) {
			event.preventDefault();
			this.getNextSlide(true);
		}

		if (event.keyCode === 39) {
			event.preventDefault();
			this.getNextSlide();
		}
	};

	getNextSlide(prev) {
		if (this.state.animation === true) {
			return false;
		}

		let currentSlide = this.state.slide;
		let reverseAnimation = false;

		if (prev) {
			if (currentSlide > 0) {
				currentSlide--;
				reverseAnimation = true;
			} else {
				return false;
			}
		} else {
			if (currentSlide < (this.slidesCount - 1)) {
				currentSlide++;
			} else {
				return false;
			}
		}

		this.animateMoveSlides(currentSlide, reverseAnimation);
	}

	getGivenSlide(slideNumber) {
		if (this.state.animation === true || slideNumber === this.state.slide) {
			return false;
		}

		if (slideNumber >= 0 && slideNumber < this.slidesCount) {
			this.animateMoveSlides(slideNumber, this.state.slide > slideNumber);
		}
	}

	animateMoveSlides(currentSlide, reverseAnimation) {
		if (this.isSlidesMode() === false) {
			return false;
		}

		this.setState({
			animation: true,
			reverseAnimation
		}, () => {
			setTimeout(() => {
				this.setupSlide(currentSlide);
			}, 500);

			setTimeout(() => {
				this.setState({
					animation: false,
					reverseAnimation: false,
				},);
			}, 1200);
		});
	}

	setupSlide(slideNumber) {
		if (this.isSlidesMode() === false) {
			document.body.classList.add('painted');
			return false;
		}

		if (slideNumber === 0 || slideNumber === 3) {
			document.body.classList.add('painted');
		} else {
			document.body.classList.remove('painted');
		}

		this.setState({
			slide: slideNumber,
		});

		window.location.hash = Object.keys(this.slidesHash).find(key => this.slidesHash[key] === slideNumber);
	}

	isSlidesMode(){
		try {
			return window.matchMedia("(max-width: 1024px)").matches === false;
		} catch {
			return true;
		}
	}

	watchForCurrentMobileSlide = () => {
		if (this.isSlidesMode()) {
			return false;
		}

		clearTimeout(this.scrollTimeout);

		this.scrollTimeout = setTimeout(() => {
			let currentSlides = [];

			Object.keys(this.slidesHash).map((slideHash, key) => {
				const slide = document.querySelector(slideHash);
				const fromTop = slide.getBoundingClientRect().y;

				if (fromTop < window.innerHeight && fromTop + slide.clientHeight > 0) {
					currentSlides.push(key);
				}
			});

			this.setState({
				currentSlides
			});
		}, 10);
	};

	checkSlidActive(slideNumber){
		if (this.isSlidesMode()) {
			return this.state.slide === slideNumber;
		}

		return this.state.currentSlides.includes(slideNumber);
	}

	render() {
		return (
			<div className="homepage">
				{this.state.animation && <div
					className={"homepage__animation" + (this.state.reverseAnimation ? ' homepage__animation--reverse' : '')}/>}
				<SlidePagination items={this.slidesCount} currentItem={this.state.slide}
				                 getSlide={this.getGivenSlide.bind(this)}/>
				<SlideWrapper active={this.checkSlidActive(0)}>
					<BardziejSlide active={this.checkSlidActive(0)} getNextSlide={this.getNextSlide.bind(this)}/>
				</SlideWrapper>
				<SlideWrapper active={this.checkSlidActive(1)} backgroundColor='#fff'>
					<WeSlide active={this.checkSlidActive(1)}/>
				</SlideWrapper>
				<SlideWrapper active={this.checkSlidActive(2)} backgroundColor='#fff' overTop={true}>
					<CompetencesSlide active={this.checkSlidActive(2)}/>
				</SlideWrapper>
				<SlideWrapper active={this.checkSlidActive(3)} backgroundColor='#fff' hasSlides={true}>
					<RealizationsSlide active={this.checkSlidActive(3)}/>
				</SlideWrapper>
				<SlideWrapper active={this.checkSlidActive(4)} backgroundColor='#fff'><ClientsSlide/></SlideWrapper>
				<SlideWrapper active={this.checkSlidActive(5)} backgroundColor='#fff'><ContactSlide titleTag="h1" /></SlideWrapper>
			</div>
		);
	}

}

const customProps = {
	localeKey: 'home',
};

export default withLayout(customProps)(Index);
