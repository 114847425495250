import React from "react";
import BardziejButton from "../BardziejButton";
import {withPrefix} from 'gatsby';
import {FormattedMessage} from "react-intl";
import HomepageSlide from "./HomepageSlide";

class WeSlide extends HomepageSlide {

	render() {
		return (
			<section className='bardziej-slide' id="my">
				<div className="slide__column slide__column--head">
					<h1 className="slide__title">
						<FormattedMessage id='home.we.title'/>
					</h1>
					<h4 className="slide__subtitle">
						<FormattedMessage id='home.we.subtitle'/>
					</h4>
				</div>
				<video ref={this.setVideoPlayerRef} className="video-full" loop muted playsInline preload="none"
				       key={this.state.videoSize}>
					<source src={withPrefix( `/video/my_${this.state.videoSize}.mp4`)} type="video/mp4"/>
				</video>
				<div className="slide__column slide__column--content">
					<div className="slide__text">
						<FormattedMessage id='home.we.text'/>
					</div>
					<BardziejButton title="home.we.button" href='/my/'/>
				</div>
			</section>
		);
	}
}

export default WeSlide;
