import React from "react";
import BardziejButton from "../BardziejButton";
import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import {FormattedMessage} from "react-intl";
import CompetenceItem from "./CompetenceItem";
import CloseIcon from "../../shared/icons/close.svg";
import {withPrefix} from "../../../.cache/gatsby-browser-entry";
import HomepageSlide from "./HomepageSlide";

class CompetencesSlide extends HomepageSlide {

	constructor(props) {
		super(props);

		this.state = {
			videoSize: 'fhd',
			mobileVersion: false,
			marketingDetails: false,
			softwareDetails: false,
		};

		this.scrollTop = null;
	}

	componentDidMount() {
		super.componentDidMount();

		window.addEventListener('resize', this.checkForMobileVersion);

		this.checkForMobileVersion();
	}

	componentWillReceiveProps(nextProps, nextContext) {
		super.componentWillReceiveProps(nextProps, nextContext);

		if (this.props.active === true && nextProps.active === false) {
			// this.setState({
			// 	marketingDetails: false,
			// 	softwareDetails: false,
			// });
			// this.switchGlobalStyles(false);
		}
	}

	componentWillUnmount() {
		super.componentWillUnmount();

		window.removeEventListener('resize', this.checkForMobileVersion);
	}

	checkForMobileVersion = () => {
		this.setState({
			mobileVersion: window.matchMedia("(max-width: 1024px)").matches,
		});
	};

	toggleMarketingDetails() {
		this.setState({
			marketingDetails: !this.state.marketingDetails,
			softwareDetails: false,
		});

		this.switchGlobalStyles(!this.state.marketingDetails);
	}

	toggleSoftwareDetails() {
		this.setState({
			marketingDetails: false,
			softwareDetails: !this.state.softwareDetails,
		});

		this.switchGlobalStyles(!this.state.softwareDetails);
	}

	switchGlobalStyles(competencesVisible) {
		if (competencesVisible) {
			this.scrollTop = window.scrollY;
			setTimeout(() => {
				document.body.classList.add('mobile-transparent-header');
			}, 100);
		} else {
			document.body.classList.remove('mobile-transparent-header');

			if (this.scrollTop) {
				window.scrollTo(0, this.scrollTop);
			}
		}
	}

	render() {
		const competencesOpen = this.state.softwareDetails || this.state.marketingDetails;

		return (
			<StaticQuery
				query={graphql`
	        query CompetenceImagesQuery {
	          creativeBrain: file(relativePath: { eq: "kompetencje/creative-brain.jpg" }) {
	            childImageSharp {
	                fixed(width: 698, quality: 100) {
	                ...GatsbyImageSharpFixed
	              }
	            }
	          },
	          creativeBrainMobile: file(relativePath: { eq: "kompetencje/creative-brain-mobile.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 100, srcSetBreakpoints: [320, 360, 480, 620, 640, 768, 1024]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
	          techBrain: file(relativePath: { eq: "kompetencje/tech-brain.jpg" }) {
	            childImageSharp {
	                fixed(width: 698, quality: 100) {
	                ...GatsbyImageSharpFixed
	              }
	            }
	          },
	          techBrainMobile: file(relativePath: { eq: "kompetencje/tech-brain-mobile.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1024, quality: 100, srcSetBreakpoints: [320, 360, 480, 620, 640, 768, 1024]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
	        }
	      `}
				render={data => (
					<section className='competences-slide' id="kompetencje">
						<div
							className={"slide__column slide__column--head" + (competencesOpen ? ' slide__column--open' : '')}>
							<div
								className={"slide__department" + (this.state.softwareDetails ? ' slide__department--blank' : '')}
								onClick={this.toggleMarketingDetails.bind(this)}>
								<div>
									<h2 className="slide__department-name">
										<FormattedMessage id='home.competences.marketing.name'/>
									</h2>
									<div className="slide__department-description">
										<FormattedMessage id='home.competences.marketing.description'/>
									</div>
								</div>
								<BardziejButton
									title="home.competences.more"
									modifier={competencesOpen ? 'bardziej-button--white' : ''}
									closableText="home.competences.close"
									closable={this.state.marketingDetails}
								/>
							</div>
							<div
								className={"slide__department" + (this.state.marketingDetails ? ' slide__department--blank' : '')}
								onClick={this.toggleSoftwareDetails.bind(this)}>
								<div>
									<h2 className="slide__department-name">
										<FormattedMessage id='home.competences.software.name'/>
									</h2>
									<div className="slide__department-description">
										<FormattedMessage id='home.competences.software.description'/>
									</div>
								</div>
								<BardziejButton
									title="home.competences.more"
									modifier={competencesOpen ? 'bardziej-button--white' : ''}
									closableText="home.competences.close"
									closable={this.state.softwareDetails}
								/>
							</div>
						</div>
						<video ref={this.setVideoPlayerRef} className="slide__video-background video-full" loop
						       muted preload="none"
						       playsInline key={this.state.videoSize}>
							<source src={withPrefix(`/video/competences_${this.state.videoSize}.mp4`)}
							        type="video/mp4"/>
						</video>
						<div
							className={"slide__column slide__column--content" + (this.state.marketingDetails || this.state.softwareDetails ? ' slide__column--hidden' : '')}>
							<h1 className="slide__title">
								<FormattedMessage id='home.competences.title'/>
							</h1>
							<div className="slide__text">
								<FormattedMessage id='home.competences.text'/>
							</div>
						</div>
						<div className={"competence" + (this.state.marketingDetails ? ' competence--active' : '')}>
							<div className="competence__background">
								{this.state.mobileVersion ?
									<Img className="image" fluid={data.creativeBrainMobile.childImageSharp.fluid}/> :
									<Img className="image" fixed={data.creativeBrain.childImageSharp.fixed}/>}
								<div className="competence__mobile-details">
									<div className="competence__heading">
										<FormattedMessage id='home.competences.marketing.name'/>
									</div>
									<div>
										<button className="competence__close-button"
										        onClick={this.toggleMarketingDetails.bind(this)}>
											<CloseIcon className="competence__close-icon"/>
										</button>
									</div>
								</div>
							</div>
							<div className="competence__content">
								<div className="competence__items">
									<CompetenceItem
										title="home.marketingCompetences.1.title"
										desc="home.marketingCompetences.1.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.2.title"
										desc="home.marketingCompetences.2.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.3.title"
										desc="home.marketingCompetences.3.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.4.title"
										desc="home.marketingCompetences.5.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.6.title"
										desc="home.marketingCompetences.6.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.7.title"
										desc="home.marketingCompetences.7.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.8.title"
										desc="home.marketingCompetences.8.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.9.title"
										desc="home.marketingCompetences.9.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.10.title"
										desc="home.marketingCompetences.10.desc"/>
									<CompetenceItem
										title="home.marketingCompetences.11.title"
										desc="home.marketingCompetences.11.desc"/>
								</div>
							</div>
						</div>
						<div className={"competence" + (this.state.softwareDetails ? ' competence--active' : '')}>
							<div className="competence__background">
								{this.state.mobileVersion ?
									<Img className="image" fluid={data.techBrainMobile.childImageSharp.fluid}/> :
									<Img className="image" fixed={data.techBrain.childImageSharp.fixed}/>}
								<div className="competence__mobile-details">
									<div className="competence__heading">
										<FormattedMessage id='home.competences.software.name'/>
									</div>
									<div>
										<button className="competence__close-button"
										        onClick={this.toggleSoftwareDetails.bind(this)}>
											<CloseIcon className="competence__close-icon"/>
										</button>
									</div>
								</div>
							</div>
							<div className="competence__content">
								<div className="competence__items">
									<CompetenceItem
										title="home.softwareCompetences.1.title"
										desc="home.softwareCompetences.1.desc"
										url={'/kompetencje/strony-www/'}
									/>
									<CompetenceItem
										title="home.softwareCompetences.2.title"
										desc="home.softwareCompetences.2.desc"
										url={'/kompetencje/sklepy-internetowe/'}
									/>
									<CompetenceItem
										title="home.softwareCompetences.3.title"
										desc="home.softwareCompetences.3.desc"/>
									<CompetenceItem
										title="home.softwareCompetences.4.title"
										desc="home.softwareCompetences.4.desc"/>
									<CompetenceItem
										title="home.softwareCompetences.5.title"
										desc="home.softwareCompetences.5.desc"/>
									<CompetenceItem
										title="home.softwareCompetences.6.title"
										desc="home.softwareCompetences.6.desc"/>
									<CompetenceItem
										title="home.softwareCompetences.7.title"
										desc="home.softwareCompetences.7.desc"/>
								</div>
							</div>
						</div>
					</section>)}/>
		);
	}
}

export default CompetencesSlide;
