import React, {Component} from "react";
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

import balcerzak from './../../../static/images/clients/balcerzak.png';
import black from './../../../static/images/clients/black.png';
import elzab from './../../../static/images/clients/elzab.png';
import gelwe from './../../../static/images/clients/gelwe.png';
import iwoniczanka from './../../../static/images/clients/iwoniczanka.png';
import lubella from './../../../static/images/clients/lubella.png';
import mlekolaki from './../../../static/images/clients/mlekolaki.png';
import ostromecko from './../../../static/images/clients/ostromecko.png';
import pte from './../../../static/images/clients/pte.png';
import szubryt from './../../../static/images/clients/szubryt.png';
import telefonika from './../../../static/images/clients/telefonika.png';
import wysowianka from './../../../static/images/clients/wysowianka.png';
import foodcare from './../../../static/images/clients/foodcare.png';
import grupaazoty from './../../../static/images/clients/grupaazoty.png';
import hart from './../../../static/images/clients/hart.png';
import maspex from './../../../static/images/clients/maspex.png';
import mbank from './../../../static/images/clients/mbank.png';
import newag from './../../../static/images/clients/newag.png';
import oknoplast from './../../../static/images/clients/oknoplast.png';
import pgnig from './../../../static/images/clients/pgnig.png';
import toyota from './../../../static/images/clients/toyota.png';
import wisniowski from './../../../static/images/clients/wisniowski.jpg';

import {withPrefix} from "../../../.cache/gatsby-browser-entry";

class ClientsSlide extends Component {

	constructor(props) {
		super(props);

		this.clients = [
			balcerzak,
			black,
			elzab,
			gelwe,
			iwoniczanka,
			lubella,
			mlekolaki,
			ostromecko,
			pte,
			szubryt,
			telefonika,
			wysowianka,
			foodcare,
			grupaazoty,
			hart,
			maspex,
			mbank,
			newag,
			oknoplast,
			pgnig,
			toyota,
			wisniowski
		];
		this.shuffle(this.clients);

		this.randomize = null;

		this.state = {
			displayClients: [],
			oldLogoPosition: null,
			oldLogoFile: null,
			mobileVersion: false,
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.checkForMobileVersion);
		this.checkForMobileVersion();

		this.setState({
			displayClients: this.clients.slice(0, 12),
		});

		this.randomize = setInterval(this.randomizeClients.bind(this), 1100);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkForMobileVersion);
		clearInterval(this.randomize);
	}

	checkForMobileVersion = () => {
		this.setState({
			mobileVersion: window.matchMedia("(max-width: 1024px)").matches
		});
	};

	randomizeClients() {
		let availableClients = [];

		this.clients.map(client => {
			if (this.state.displayClients.includes(client) === false) {
				availableClients.push(client);
			}
			return true;
		});

		let replacedKey = Math.floor(Math.random() * this.state.displayClients.length);

		// the same to replace as previously
		if (this.state.oldLogoPosition === replacedKey) {
			replacedKey = Math.floor(Math.random() * this.state.displayClients.length);
		}

		const newValueKey = Math.floor(Math.random() * availableClients.length);
		let displayClients = this.state.displayClients;

		if (availableClients[newValueKey]) {
			const oldLogoFile = displayClients[replacedKey];
			displayClients[replacedKey] = availableClients[newValueKey];

			this.setState({
				oldLogoPosition: replacedKey,
				oldLogoFile,
				displayClients
			});
		}
	}

	shuffle(a) {
		for (let i = a.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[a[i], a[j]] = [a[j], a[i]];
		}
		return a;
	}

	renderLogos(start) {
		const logos = this.state.displayClients.slice(start, start + 6);

		return logos.map((logo, index) => (
			<div key={index} className="slide__logo">
				<img src={logo} className={this.state.oldLogoPosition === (index + start) ? "slide__new-logo" : ''} alt=""/>
				{this.state.oldLogoPosition === (index + start) && <img src={this.state.oldLogoFile} className="slide__old-logo" alt=""/>}
			</div>
		));
	}

	render() {
		return (
			<section className='clients-slide' id="klienci">
				{this.state.mobileVersion === false && <video ref={this.setVideoPlayerRef} className="video-full" autoPlay loop muted playsInline>
					<source src={withPrefix('/video/clients_fhd.mp4')} type="video/mp4"/>
				</video>}
				<div className="slide__logos slide__logos--first">
					{this.renderLogos(0)}
				</div>
				<div className="slide__content">
					<h1 className="slide__title">
						<FormattedMessage id='home.clients.title'/>
					</h1>
					<div className="slide__text">
						<FormattedHTMLMessage id='home.clients.text'/>
					</div>
				</div>
				<div className="slide__logos slide__logos--second">
					{this.renderLogos(6)}
				</div>
			</section>
		);
	}
}

export default ClientsSlide;
