import React from "react";
import './SlidePagination.scss';

const SlidePagination = ({items, currentItem, getSlide}) => {

	let pages = [];
	for (let i = 0; i < items; i++) {
		pages.push(i);
	}

	return (
		<div className="slide-pagination">
			{pages.map((page) => (
				<button
					key={page}
					className={"slide-pagination__item" + (page === currentItem ? ' slide-pagination__item--active' : '')}
					onClick={() => {getSlide(page)}}/>
			))}
		</div>
	);
};

export default SlidePagination;